import {makeStyles} from '@material-ui/core'

const useStyles = makeStyles(({palette, fontSizes, spacing}) => ({
  date: {
    display: 'block',
    fontSize: fontSizes.medium,
    color: palette.text.grey,
    fontFamily: 'CamptonMedium',
    margin: `0 auto ${spacing(0.6)}px 0`,
  },
  nextDateEvent: {
    display: 'block',
    fontSize: fontSizes.medium,
    color: `${palette.text.primary} !important`,
    paddingTop: spacing(1),
  },
}))

export default useStyles
