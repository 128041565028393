import {makeStyles} from '@material-ui/core'

const useStyles = makeStyles(
  ({fontSizes, spacing, breakpoints, layout, palette}) => ({
    testimonial: {
      maxWidth: layout.keyplayTestimonial.maxWidth,
      height: '100%',
      margin: 'auto',
      padding: spacing(6, 15),
      borderRadius: 10,
      transform: 'translateZ(0)',
      border: `solid 1px ${palette.tagButton.border}`,
      textAlign: 'center',
      marginTop: spacing(4),
      marginBottom: spacing(4),
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      [breakpoints.down('sm')]: {
        padding: spacing(3.5),
      },
    },
    quoteIcon: {
      fontSize: 80,
    },
    title: {
      marginTop: spacing(0),
      marginBottom: spacing(3),
      fontFamily: 'CamptonBook',
      fontSize: fontSizes.largeDescription,
    },
    quote: {
      fontFamily: 'CamptonLight',
      fontSize: fontSizes.description,
      fontWeight: 300,
    },
    author: {
      fontFamily: 'CamptonMedium',
      fontSize: fontSizes.description,
      fontWeight: 500,
    },
  }),
)

export default useStyles
