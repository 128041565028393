import * as R from 'ramda'
import FormatQuoteIcon from '@material-ui/icons/FormatQuote'
import React from 'react'

import {keyPlayTestimonialPropertyTypes} from 'helpers/propTypes'
import Section from 'components/UI/Section'

import useStyles from './styles'

const KeyPlayTestimonial = ({title, data}) => {
  const classes = useStyles()
  const {name, position, company, testimonial} = data
  const companyName = R.pathOr('', ['name'], company)
  const content = R.pathOr('', ['testimonial'], testimonial)

  return (
    <Section hasPaddingTop={false} hasPaddingBottom={false}>
      <div className={classes.testimonial}>
        <FormatQuoteIcon className={classes.quoteIcon} />
        <p className={classes.title}>{title}</p>
        <p className={classes.quote}>{content}</p>
        <p
          className={classes.author}
        >{`${name}, ${position}, ${companyName}`}</p>
      </div>
    </Section>
  )
}

KeyPlayTestimonial.propTypes = keyPlayTestimonialPropertyTypes

KeyPlayTestimonial.defaultProps = {}

export default KeyPlayTestimonial
