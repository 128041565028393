import {bool, func} from 'prop-types'
import classNames from 'classnames'
import IconButton from '@material-ui/core/IconButton'
import LeftArrowIcon from '@material-ui/icons/KeyboardBackspaceSharp'
import React, {useState} from 'react'

import useStyles from './styles'

const RoundButtonSlider = ({
  increment,
  decrement,
  isCentered,
  hasMarginTop,
}) => {
  const classes = useStyles()
  const [stateLeft, setStateLeft] = useState(false)
  const [stateRight, setStateRight] = useState(false)

  const handleDecrement = () => {
    decrement()
    setStateLeft(!stateLeft)
  }
  const handleIncrement = () => {
    increment()
    setStateRight(!stateRight)
  }

  return (
    <div
      className={classNames(classes.slideNavigation, {
        [classes.centered]: isCentered,
        [classes.marginTop]: hasMarginTop,
      })}
    >
      <IconButton
        className={classNames(classes.button, {
          // [classes.loading]: isLoading && stateLeft,
        })}
        onClick={handleDecrement}
        onAnimationEnd={() => setStateLeft(false)}
        aria-label="left-arrow"
      >
        <LeftArrowIcon />
      </IconButton>
      <IconButton
        className={classNames(classes.rightArrow, {
          // [classes.loading]: isLoading && stateRight,
        })}
        onClick={handleIncrement}
        onAnimationEnd={() => setStateRight(false)}
        aria-label="right-arrow"
      >
        <LeftArrowIcon />
      </IconButton>
    </div>
  )
}

RoundButtonSlider.propTypes = {
  decrement: func,
  hasMarginTop: bool,
  increment: func,
  isCentered: bool,
}
RoundButtonSlider.defaultProps = {
  decrement: Function.prototype,
  hasMarginTop: false,
  increment: Function.prototype,
  isCentered: false,
}

export default RoundButtonSlider
