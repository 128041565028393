import {makeStyles} from '@material-ui/core'

const useStyles = makeStyles(({palette, breakpoints, spacing, fontSizes}) => ({
  container: {
    marginBottom: spacing(14),
  },
  verticalContainer: {
    position: 'fixed',
    top: 175,
    left: '5%',
    zIndex: 5,
    [breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  wrap: {
    textAlign: 'center',
    position: 'relative',
    '&::before': {
      content: '""',
      height: 1,
      position: 'absolute',
      top: '50%',
      left: 0,
      background: palette.background.gray,
      width: '100%',
      transform: 'translateY(-50%)',
      [breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  },
  label: {
    textAlign: 'center',
    fontWeight: 500,
    fontSize: fontSizes.subtitle,
    fontFamily: 'CamptonMedium',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },
  links: {
    marginTop: spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
    margin: 'auto',
    [breakpoints.down('sm')]: {
      marginTop: spacing(1),
    },
  },
  logos: {
    margin: 'auto',
    padding: spacing(0, 3),
    background: 'white',
  },
  verticalLogos: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    background: 'transparent',
  },
  shareButton: {
    display: 'inline-block',
    alignItems: 'center',
    verticalAlign: 'middle',
    margin: spacing(0.75),
    borderRadius: '100%',
    border: `solid 1px ${palette.socialButton.border}`,
    background: 'white',

    padding: spacing(1.6),
    '& img': {
      width: 24,
      margin: 'auto',
      alignItems: 'center',
      verticalAlign: 'middle',
    },
    [breakpoints.down('sm')]: {
      justifyContent: 'space-evenly',
    },
  },
}))

export default useStyles
