import * as R from 'ramda'
import Paper from '@material-ui/core/Paper'
import React from 'react'

import {keysPropTypes} from 'helpers/propTypes'
import Section from 'components/UI/Section'

import useStyles from './styles'

const Keys = ({title, key1, key2, key3}) => {
  const classes = useStyles()

  return (
    <>
      {key1 && (
        <Section hasPaddingTop={false} hasPaddingBottom={false}>
          <div className={classes.container}>
            <h2 className={classes.title}>{title}</h2>
            <div className={classes.row}>
              {key1 && (
                <div className={classes.column}>
                  <Paper className={classes.paper}>
                    <div className={classes.number}>01</div>
                    <div className={classes.key}>
                      {R.pathOr(' ', ['keyResult1'], key1)}
                    </div>
                  </Paper>
                </div>
              )}
              {key2 && (
                <div className={classes.column}>
                  <Paper className={classes.paper}>
                    <div className={classes.number}>02</div>
                    <div className={classes.key}>
                      {R.pathOr(' ', ['keyResult2'], key2)}
                    </div>
                  </Paper>
                </div>
              )}
              {key3 && (
                <div className={classes.column}>
                  <Paper className={classes.paper}>
                    <div className={classes.number}>03</div>
                    <div className={classes.key}>
                      {R.pathOr(' ', ['keyResult3'], key3)}
                    </div>
                  </Paper>
                </div>
              )}
            </div>
          </div>
        </Section>
      )}
    </>
  )
}

Keys.propTypes = keysPropTypes

Keys.defaultProps = {}

export default Keys
