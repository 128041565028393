import {makeStyles} from '@material-ui/core'

const useStyles = makeStyles(
  ({palette, breakpoints, spacing, fontSizes, layout, shadow}) => ({
    container: {
      backgroundColor: palette.background.greyLight,
      display: 'flex',
      flexDirection: 'column',

      [breakpoints.up('md')]: {
        marginBottom: spacing(8),
      },
      [breakpoints.down('sm')]: {
        alignItems: 'center',
        backgroundColor: 'white',
      },
    },
    heroContainer: {
      backgroundColor: palette.background.greyLight,
      display: 'flex',
      height: layout.keyplayHeader.height,
      width: layout.keyplayHeader.width,
      margin: 'auto',
      '& *': {},
      [breakpoints.down('sm')]: {
        backgroundColor: palette.background.default,
        flexDirection: 'column',
        paddingLeft: 0,
        alignItems: 'center',
        minHeight: 470,
        width: '100%',
        marginBottom: spacing(4),
      },
    },
    heroContent: {
      display: 'flex',
      width: '50%',
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: spacing(3.5),
      [breakpoints.down('sm')]: {
        width: '100%',
      },
      [breakpoints.up('md')]: {
        paddingLeft: spacing(12),
      },
    },
    backLink: {
      color: palette.primary.main,
      fontWeight: 600,
      fontSize: fontSizes.description,
      fontFamily: 'CamptonSemiBold',
      textDecoration: 'none',
      textTransform: 'none',
      [breakpoints.down('sm')]: {
        visibility: 'hidden',
      },
    },
    titleHero: {
      paddingTop: spacing(5),
      display: 'flex',
      flexDirection: 'column',
      [breakpoints.down('sm')]: {
        padding: 0,
      },
    },
    tags: {
      paddingTop: spacing(1),
    },
    box: {
      zIndex: 8,
      height: layout.keyplayHeader.box.height,
      maxWidth: layout.keyplayHeader.box.maxWidth,
      margin: '445px auto',
      backgroundColor: 'white',
      borderRadius: spacing(0, 5),
      transform: 'translateZ(0)',
      boxShadow: shadow.keyplayHeader,
      marginLeft: 'auto',
      marginRight: 'auto',
      left: 0,
      right: 0,
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      padding: 15,
      [breakpoints.up('sm')]: {
        position: 'absolute',
      },
      [breakpoints.down('sm')]: {
        position: 'relative',
        flexDirection: 'column',
        height: 'auto',
        width: '90%',
        margin: spacing(-8, 4, 2, 4),
        '& div': {
          margin: spacing(1, 'auto'),
        },
      },
    },
    keyNumbers: {
      display: 'flex',
      flex: '30%',
      flexDirection: 'column',
      alignItems: 'center',

      margin: spacing(0, 5),
      textAlign: 'center',
      '& *': {
        margin: 0,
      },
      '& h3': {
        fontFamily: 'CamptonMedium',
        fontWeight: 500,
        fontSize: fontSizes.subTitle,
      },
      '& p': {
        fontFamily: 'CamptonLight',
        fontWeight: 300,
        fontSize: fontSizes.description,
      },
    },
    tagsContainer: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      maxWidth: '40vw',
      marginTop: spacing(2),
      '& Button': {
        margin: 3,
        [breakpoints.down('sm')]: {
          margin: 8,
        },
      },
      [breakpoints.down('sm')]: {
        maxWidth: '100%',
      },
    },
    tag: {
      textTransform: 'none',
      fontSize: fontSizes.small,
      maxHeight: layout.tags.maxHeight,
      [breakpoints.down('sm')]: {
        padding: 8,
      },
    },
    departmentTags: {
      color: palette.tags.departments.color,
      backgroundColor: palette.tags.departments.backgroundColor,
    },
    industrieTags: {
      color: palette.secondary.main,
      backgroundColor: palette.tags.industries.backgroundColor,
    },
    partnerTags: {
      color: palette.primary.main,
      backgroundColor: palette.tags.partners.backgroundColor,
    },
    tagTags: {
      color: palette.primary.main,
      backgroundColor: palette.tags.tags.backgroundColor,
    },

    heroImage: {
      width: '100%',
      maxWidth: layout.keyplayHeader.maxWidth,
      [breakpoints.down('sm')]: {
        maxWidth: '90%',
        maxHeight: '20vh',
        borderRadius: spacing(2, 5),
        transform: 'translateZ(0)',
      },
      [breakpoints.up('md')]: {
        maxWidth: '50%',
      },
    },
  }),
)

export default useStyles
